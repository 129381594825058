// eslint-disable-next-line
import React, { useState } from "react";
import {
  LabledTextBox,
  LabeledRadioOption,
  LabeledTextArea,
  LabeledDatePicker
} from "../components/LabeledControls";
import styled from "styled-components";
import Button from "../components/button";
import { FlexHorizontal } from "../components/FlexComponents";
import { LoadingModal, ErrorModal, MessageModal } from "../components/modal";
import moment from "moment";

import { createLabeledField } from "../lib/field";
import { LabeledTextControl } from "../components/LabeledControlsReloaded";
const Validators = require("../validators");

const FieldSpacer = styled.div`
  margin: 5px 0px;
`;

const NewConfirmation = props => {
  const [reservationNumber, setReservationNumber] = useState("");
  const [reservationNumberError, setReservationNumberError] = useState(null);

  const [guestLastName, setGuestLastName] = useState("");
  const [guestLastNameError, setGuestLastNameError] = useState(null);

  const [guestEmail, setGuestEmail] = useState("");
  const [guestEmailError, setGuestEmailError] = useState(null);

  const [numberGuests, setNumberGuests] = useState("");
  const [numberGuestsError, setNumberGuestsError] = useState(null);

  const [checkIn, setCheckIn] = useState(new Date());
  const [checkInError, setCheckInError] = useState(null);

  const [checkOut, setCheckOut] = useState(new Date());
  const [checkOutError, setCheckOutError] = useState(null);

  const [cost, setCost] = useState("");
  const [costError, setCostError] = useState(null);

  const [refundable, setRefundable] = useState(null);

  const [deposit, setDeposit] = useState("");
  const [depositError, setDepositError] = useState(null);

  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(null);

  const [submitError, setSubmitError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [confirmationCodeMessage, setConfirmationCodeMessage] = useState(null);

  const refundableTimeFrame = createLabeledField(
    "Refundable Time Frame (days)",
    "3", //must be a string ... or it breaks
    { validators: [Validators.ValidateNotEmpty, Validators.ValidateIsNumber] }
  );

  const ValidateNotEmpty = (value, fn) => {
    if (value.length > 0) {
      fn(null);
      return true;
    } else {
      fn("A value must be provided");
      return false;
    }
  };

  const ValidateIsNumber = (value, fn) => {
    if (isNaN(parseInt(value, 10))) {
      fn("A numeric value must be provided");
      return false;
    }
    fn(null);
    return true;
  };

  const validatorsOnSubmit = [];

  const generateTextFieldWithValidator = (
    label,
    value,
    setValueFn,
    error,
    setErrorFn,
    validators
  ) => {
    const _validate = currentValue => {
      if (!validators) return;
      for (let validator of validators) {
        //console.log(`validator [${validator}] - ${currentValue}`);
        if (!validator(currentValue, setErrorFn)) {
          return false;
        }
      }
      return true;
    };

    validatorsOnSubmit.push(() => _validate(value));

    return {
      label,
      invalidValue: error,
      error,
      onTextChange: newValue => {
        setValueFn(newValue);
        if (error) _validate(newValue);
      },
      onValueChange: newValue => {
        setValueFn(newValue);
        if (error) _validate(newValue);
      },
      onBlur: () => _validate(value),
      value
    };
  };

  const ValidateBeforeSubmit = () => {
    for (let validator of validatorsOnSubmit) {
      if (!validator()) {
        return "Please correct your submittion before continuing.";
      }
    }

    /*const errorFields = [
      reservationNumberError,
      guestLastNameError,
      guestEmailError,
      numberGuestsError,
      checkInError,
      checkOutError,
      costError,
      depositError,
      descriptionError
    ];

    for (let field of errorFields) {
      if (field)
        return "Please fix the information provided before submitting.";
    }*/
    if (!refundableTimeFrame.validate()) {
      return "Please correct your submittion before continuing.";
    }

    if (refundable === null)
      return "Please indicate if this confirmation is refundable or non-refundable.";

    return null;
  };

  const doCreateNew = () => {
    const error = ValidateBeforeSubmit();
    if (error) {
      setSubmitError(error);
    } else {
      //console.log(checkIn);

      //console.log(moment(checkIn).format("YYYY-MM-DD") + "T11:00:00+07:00");
      setLoading(true);
      fetch("/api/admin/confirmations", {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json"
          // "Content-Type": "application/x-www-form-urlencoded",
        },
        body: JSON.stringify({
          reservationNumber,
          guestLastName,
          numberGuests,
          guestEmail,
          checkIn: moment(checkIn).format("YYYY-MM-DD") + "T11:00:00+07:00",
          checkOut: moment(checkOut).format("YYYY-MM-DD") + "T11:00:00+07:00",
          cost,
          deposit,
          refundable,
          description,
          refundableTimeFrame: refundable ? refundableTimeFrame.get() : null
        })
      })
        .then(res => res.json())
        .then(
          res =>
            setLoading(false) ||
            setConfirmationCodeMessage(
              `New Confirmation Link: ${window.location.origin}/confirmation/${
                res.confirmationCode
              }`
            )
        );
    }
  };

  return (
    <div>
      <FieldSpacer>
        <LabledTextBox
          {...generateTextFieldWithValidator(
            "Reservation Number",
            reservationNumber,
            setReservationNumber,
            reservationNumberError,
            setReservationNumberError,
            [ValidateNotEmpty]
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabledTextBox
          {...generateTextFieldWithValidator(
            "Primary Guest Last Name",
            guestLastName,
            setGuestLastName,
            guestLastNameError,
            setGuestLastNameError,
            [ValidateNotEmpty]
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabledTextBox
          label={"Primary Guest Email"}
          {...generateTextFieldWithValidator(
            "Primary Guest Email",
            guestEmail,
            setGuestEmail,
            guestEmailError,
            setGuestEmailError,
            [ValidateNotEmpty]
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabledTextBox
          {...generateTextFieldWithValidator(
            "Number of Guests",
            numberGuests,
            setNumberGuests,
            numberGuestsError,
            setNumberGuestsError,
            [ValidateNotEmpty, ValidateIsNumber]
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabeledDatePicker
          {...generateTextFieldWithValidator(
            "Check-In",
            checkIn,
            setCheckIn,
            checkInError,
            setCheckInError,
            []
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabeledDatePicker
          {...generateTextFieldWithValidator(
            "Check-Out",
            checkOut,
            setCheckOut,
            checkOutError,
            setCheckOutError,
            []
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabledTextBox
          {...generateTextFieldWithValidator(
            "Total Cost",
            cost,
            setCost,
            costError,
            setCostError,
            [ValidateNotEmpty, ValidateIsNumber]
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <LabeledRadioOption
          label={"Reservation Type"}
          onValueChange={value => {
            value === "refundable" ? setRefundable(true) : setRefundable(false);
            setDepositError(null);
          }}
          name={"reservationsType"}
          options={[
            { value: "refundable", displayValue: "Refundable" },
            { value: "nonrefundable", displayValue: "Non-Refundable" }
          ]}
          value={
            refundable === null
              ? null
              : refundable
              ? "refundable"
              : "nonrefundable"
          }
        />
      </FieldSpacer>
      {refundable && (
        <>
          <FieldSpacer>
            <LabledTextBox
              {...generateTextFieldWithValidator(
                "Deposit",
                deposit,
                setDeposit,
                depositError,
                setDepositError,
                [ValidateNotEmpty, ValidateIsNumber]
              )}
            />
          </FieldSpacer>
          <FieldSpacer>
            <LabeledTextControl
              {...refundableTimeFrame.params}
              desktopLabelWidth={"160px"}
              desktopControlWidth={"50px"}
            />
          </FieldSpacer>
        </>
      )}
      <FieldSpacer>
        <LabeledTextArea
          rows="4"
          cols="80"
          {...generateTextFieldWithValidator(
            "Description",
            description,
            setDescription,
            descriptionError,
            setDescriptionError,
            [ValidateNotEmpty]
          )}
        />
      </FieldSpacer>
      <FieldSpacer>
        <FlexHorizontal space>
          <Button onClick={() => doCreateNew()}>Create</Button>
          <Button onClick={() => props.history.push("/admin")}>Back</Button>
        </FlexHorizontal>
      </FieldSpacer>
      {submitError && (
        <ErrorModal
          error={submitError}
          onDismiss={() => setSubmitError(null)}
        />
      )}
      {loading && <LoadingModal />}
      {confirmationCodeMessage && (
        <MessageModal
          message={confirmationCodeMessage}
          onDismiss={() => props.history.push("/admin")}
        />
      )}
    </div>
  );
};

export default NewConfirmation;
