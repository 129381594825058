// eslint-disable-next-line
import React, { Component } from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";
import Checkbox from "../components/checkbox";

const RefundableTermsAndConditions = props => {
  return (
    <>
      <p>
        <b>Deposit:</b> Laluna Hoi An Riverside Hotel &amp; Spa will charge the
        specified credit card a{" "}
        <NumberFormat
          value={props.deposit}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
        />{" "}
        deposit. The deposit can be refunded in accordance with the booking
        cancellation policy. If the information provided is invalid your booking
        may be cancelled.
      </p>
      <p>
        <b>Cancellation:</b> The reservation may be cancelled up to{" "}
        {props.refundableTimeFrame} days before check in for a full refund
        including deposit. If cancelled within {props.refundableTimeFrame} days
        of the check in time the total cost of the first night will be charged
        to the credit card specified.
      </p>
      <p>
        <b>No Show:</b> Guests who do not check in during their reservation
        period and do not provide notice to the hotel, will be considered no
        show. Laluna Hoi An Riverside Hotel &amp; Spa will charge the full
        amount of the reservation to the credit card in the event of no show.
      </p>
      <p>
        <b>Accommodation:</b> Laluna Hoi An Riverside Hotel &amp; Spa reserves
        the right to substitute and confirm alternative accommodation of a
        comparable or superior standard if necessary should that described in
        the accommodation description not be available.
      </p>
      <p>
        <b>Final Payment:</b> Final payment will be made at the hotel prior to
        check out.
      </p>
    </>
  );
};

const NonRefundableTermsAndConditions = props => {
  return (
    <>
      <p>
        <b>Payment:</b> Laluna Hoi An Riverside Hotel &amp; Spa will charge the
        specified credit card the reservation total cost{" "}
        <NumberFormat
          value={props.cost}
          displayType={"text"}
          thousandSeparator={true}
          prefix={"$"}
          decimalScale={2}
          fixedDecimalScale={true}
        />
        . If the information provided is invalid your booking may be cancelled.
      </p>
      <p>
        <b>Cancellation:</b> This reservation is NON-REFUNDABLE and may not be
        cancelled.
      </p>
      <p>
        <b>Accommodation:</b> Laluna Hoi An Riverside Hotel &amp; Spa reserves
        the right to substitute and confirm alternative accommodation of a
        comparable or superior standard if necessary should that described in
        the accommodation description not be available.
      </p>
    </>
  );
};

const TermsBox = styled.div`
  height: 150px;
  overflow-y: scroll;
  border: 1px solid #aaa;
  padding: 5px;
  background-color: #eee;
`;

const DescriptionTitle = styled.div`
  font-size: 95%;
  margin: 5px 0px;
`;

const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px 0px;
`;

const TermsAndConditions = props => {
  return (
    <>
      <DescriptionTitle>Terms and Conditions</DescriptionTitle>
      <TermsBox>
        {props.refundable ? (
          <RefundableTermsAndConditions {...props} />
        ) : (
          <NonRefundableTermsAndConditions {...props} />
        )}
      </TermsBox>
      <FlexVertical>
        <div>
          <Checkbox
            onChange={value => props.termCheckChange(value)}
            checked={props.termsChecked}
          />
          <label>&nbsp;I agree to the terms and conditions.</label>
        </div>
        <div>
          <Checkbox
            onChange={value => props.confirmCheckChange(value)}
            checked={props.confirmChecked}
          />
          <label>
            &nbsp;I confirm the reservation details shown are correct.
          </label>
        </div>
      </FlexVertical>
    </>
  );
};

export default TermsAndConditions;
