import React, { useState } from "react";
import { Route, Switch } from "react-router-dom";
import ConfirmationList from "./ConfirmationList";
import NewConfirmation from "./NewConfirmation";
import ConfirmationStatus from "./ConfirmationStatus";
import { FlexVertical } from "../components/FlexComponents";
import UserAdmin from "../userAdmin/UserAdminPage";
import Login from "./Login";
import { getMe } from "../api/admin/users";
import ProfilePage from "../userAdmin/Profile";
import SettingsAdmin from "./settings/SettingsAdmin";

export const Page = props => {
  const [me, setMe] = useState(null);
  //console.log(document.cookie);
  //TODO: auto logout when session cookie "connect.sid" is gone
  if (!me) {
    getMe()
      .then(res => setMe(res))
      .catch(error => {
        if (
          error.name === "UnauthorizedError" &&
          window.location.pathname !== "/admin/login"
        ) {
          console.log(window.location.pathname);
          props.history.push(
            `/admin/login?redirect=${encodeURIComponent(
              window.location.pathname
            )}`
          );
        }
      });
  }

  return (
    <>
      <FlexVertical>
        <Switch>
          <Route
            exact
            path={props.match.path}
            render={renderProps => (
              <ConfirmationList {...props} {...renderProps} me={me} />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/login`}
            render={renderProps => (
              <Login {...props} {...renderProps} me={me} />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/new`}
            render={renderProps => (
              <NewConfirmation {...props} {...renderProps} me={me} />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/users`}
            render={renderProps => (
              <UserAdmin {...props} {...renderProps} me={me} />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/settings`}
            render={renderProps => (
              <SettingsAdmin {...props} {...renderProps} me={me} />
            )}
          />
          <Route
            exact
            path={`${props.match.path}/me`}
            render={renderProps => (
              <ProfilePage {...props} {...renderProps} me={me} />
            )}
          />
          <Route
            path={`${props.match.path}/:code`}
            render={renderProps => (
              <ConfirmationStatus {...props} {...renderProps} me={me} />
            )}
          />
        </Switch>
      </FlexVertical>
    </>
  );
};
