import React, { useState } from "react";
import ConfirmationListItem, {
  ConfirmationListItemHeader
} from "./ConfirmationListItem";
import { FlexVertical, FlexHorizontal } from "../components/FlexComponents";
import styled from "styled-components";
import Button from "../components/button";
import { allow } from "../lib/permissions";

import { getConfirmations } from "../api/admin/confirmations";
import Checkbox from "../components/checkbox";
import { createLabeledField } from "../lib/field";
import { LabeledTextControl } from "../components/LabeledControlsReloaded";

const SectionWrapper = styled.div`
  padding: 20px;
  border-bottom: 2px solid #888;
`;

const ConfirmationList = props => {
  const [confirmations, setConfirmations] = useState(null);
  const [showComplete, setShowComplete] = useState(false);
  const filter = createLabeledField("Search", "", {});

  if (!confirmations) {
    getConfirmations()
      .then(res => setConfirmations(res))
      .catch(error => console.error(error));
    return <>Loading...</>;
  }

  return (
    <>
      <SectionWrapper>
        <FlexHorizontal space>
          {allow(props.me, "createconfirm") && (
            <Button onClick={() => props.history.push("/admin/new")}>
              Create New Confirmation
            </Button>
          )}
          <Button onClick={() => props.history.push("/admin/me")}>
            Update Password
          </Button>
        </FlexHorizontal>
      </SectionWrapper>
      <SectionWrapper>
        <div>
          <LabeledTextControl {...filter.params} />
        </div>
        <div>
          <Checkbox
            onChange={value => setShowComplete(value)}
            checked={showComplete}
          />
          <label>&nbsp;Show Complete</label>
        </div>
      </SectionWrapper>
      <SectionWrapper>
        <FlexVertical>
          <ConfirmationListItemHeader />
          {confirmations
            .filter(
              c =>
                showComplete ||
                !["INVALID", "REFUNDED", "COMPLETE", "HOTELCANCEL"].includes(
                  c.state
                )
            )
            .filter(c =>
              filter.get().length > 0
                ? c.lastName
                    .toLowerCase()
                    .startsWith(filter.get().toLowerCase()) ||
                  c.reservationNumber
                    .toLowerCase()
                    .startsWith(filter.get().toLowerCase()) ||
                  c.confirmationCode
                    .toLowerCase()
                    .startsWith(filter.get().toLowerCase())
                : true
            )
            .map((c, i) => (
              <ConfirmationListItem {...c} key={i} />
            ))}
        </FlexVertical>
      </SectionWrapper>
    </>
  );
};

export default ConfirmationList;
