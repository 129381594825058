import React, { useState } from "react";
import {
  getSettings,
  updateSetting,
  emailTest
} from "../../api/admin/settings";
import { LabeledTextControl } from "../../components/LabeledControlsReloaded";
import Button from "../../components/button";
import { createLabeledField } from "../../lib/field";

const SettingsAdmin = props => {
  const [serverSettings, setServerSettings] = useState(null);
  const emailTestField = createLabeledField("Email Test To", "", {});
  const settings = [
    "email.host",
    "email.port",
    "email.secure",
    "email.user",
    "email.pass",
    "email.location"
  ];
  const loadSettings = () => {
    getSettings().then(data => {
      if (data) {
        const toStore = [];
        for (let setting of settings) {
          let added = false;
          for (let item of data) {
            if (setting === item.identifier) {
              toStore.push(item);
              added = true;
            }
          }

          if (!added) {
            toStore.push({ identifier: setting, value: null });
          }
        }
        setServerSettings(toStore);
      }
    });
  };

  if (!serverSettings) {
    loadSettings();
  }

  const valueParser = value => {
    switch (value) {
      case "true":
        return true;
      case "false":
        return false;
      default:
        return value;
    }
  };
  return (
    <>
      {serverSettings &&
        serverSettings.map((item, index) => (
          <div key={index}>
            <LabeledTextControl
              label={item.identifier}
              value={serverSettings[index].value}
              onValueChange={value => {
                const newArray = [...serverSettings];
                newArray[index] = { identifier: item.identifier, value };
                setServerSettings(newArray);
              }}
            />
          </div>
        ))}
      <Button
        onClick={() => {
          for (let setting of serverSettings) {
            updateSetting(setting.identifier, valueParser(setting.value));
          }
        }}
      >
        Save
      </Button>

      <div>
        <LabeledTextControl {...emailTestField.params} />
        <Button
          onClick={() => {
            emailTest(emailTestField.get()).then(res => console.log(res));
          }}
        >
          Send Message
        </Button>
      </div>
    </>
  );
};

export default SettingsAdmin;
