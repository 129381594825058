import React, { useState } from "react";

//import styled from "styled-components";
import { FlexVertical } from "../components/FlexComponents";
import { LabledTextBox } from "../components/LabeledControls";
import { createLabeledField } from "../lib/field";
import { ValidateNotEmpty } from "../validators";
import Button from "../components/button";

import { updateUser } from "../api/admin/users";
import { MessageModal } from "../components/modal";

const ProfilePage = props => {
  const { me } = props;
  const [changeComplete, setChangeComplete] = useState(false);
  const passwordField = createLabeledField("Password", "", {
    validators: [ValidateNotEmpty]
  });
  const confirmPassword = createLabeledField("Confirm Password", "", {
    validators: [ValidateNotEmpty]
  });
  const validate = () => {
    if (!passwordField.validate() || !confirmPassword.validate()) {
      return false;
    }
    if (passwordField.get() !== confirmPassword.get()) {
      confirmPassword.setError("Passwords must match");
      return false;
    }
    return true;
  };

  const updatePassword = () => {
    if (validate()) {
      updateUser(me.email, { password: passwordField.get() }).then(res => {
        setChangeComplete(true);
      });
    }
  };
  return (
    <>
      <FlexVertical>
        <div>
          This page is still under development, it currently only provides the
          basic functionality to reset your password
        </div>
        <div>
          <LabledTextBox {...passwordField.params} password />
          <LabledTextBox {...confirmPassword.params} password />
          <Button onClick={updatePassword}>Reset Password</Button>
        </div>
      </FlexVertical>
      {changeComplete && (
        <MessageModal
          message={"Your password has been updated."}
          onDismiss={() => props.history.push("/admin")}
        />
      )}
    </>
  );
};

export default ProfilePage;
