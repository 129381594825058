import React from "react";
import NumberFormat from "react-number-format";
import styled from "styled-components";

const PropsFilterNumberFormat = ({
  fontWeight,
  fontFamily,
  width,
  invalidValue,
  formatted,
  ...props
}) => <NumberFormat {...props} />;

const CustomNumberFormat = styled(PropsFilterNumberFormat)`
-webkit-appearance: none;
-moz-appearance: none;
appearance: none; 

  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;

  ${props => props.fontWeight && `font-weight: ${props.fontWeight};`}
  ${props => props.fontFamily && `font-family: ${props.fontFamily};`}
  ${props => props.width && `width: ${props.width};`}
  ${props => props.invalidValue && "border-color: red;"}
  flex: 2;
  &:focus {
    outline: none;
  }

  &::placeholder {
    color: grey;
  }
`;

export default CustomNumberFormat;
