export class UnauthroizedError extends Error {
  constructor() {
    super("You are not authorized to access this resource");
    this.name = "UnauthorizedError";
  }
}

export class ForbiddenError extends Error {
  constructor() {
    super("You are forbidden from accessing this resource");
    this.name = "ForbiddenError";
  }
}

export const responseCodeFilter = res => {
  if (res.status < 200 || res.status >= 400) {
    switch (res.status) {
      case 401:
        throw new UnauthroizedError();
      case 403:
        throw new ForbiddenError();
      default:
        throw new Error(`There was an error fetching... code [${res.status}]`);
    }
  }
  return res;
};

export const filteredFetch = (url, object) => {
  return fetch(url, object).then(responseCodeFilter);
};
