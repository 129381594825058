import React from "react";
import styled from "styled-components";
import { Flex } from "../components/FlexComponents";

const Border = styled.div`
  border: 1px solid black;
  border-radius: 10px;
  width: 320px;
  height: 190px;
  overflow: hidden;
  background-color: #eee;
  font-family: "Share Tech Mono", monospace;
  font-weight: bold;
`;
const Stripe = styled.div`
  background-color: black;
  margin-top: 10px;
  height: 30px;
  width: 320px;
`;

const CardNumber = styled.div`
  margin-top: 40px;
  font-size: 20px;
  width: 320px;
`;
const ExpLabel = styled.div`
  font-size: 10px;
  width: 35px;
`;
const Expiration = styled.div`
  margin-left: 30px;
  margin-top: 10px;
`;
const Spacer = styled.div`
  width: 120px;
`;
const CardHolder = styled.div`
  margin-top: 25px;
`;

const CreditCardDisplay = props => {
  const formatCardNumer = number => {
    let numberParts = [];
    for (let x = 0; x < number.length; x++) {
      numberParts.push(
        <React.Fragment key={`${x}`}>{number[x]}</React.Fragment>
      );
      if ((x + 1) % 4 === 0) {
        numberParts.push(
          <React.Fragment key={`${x}-space`}>&nbsp;&nbsp;</React.Fragment>
        );
      }
    }
    return numberParts;
  };
  return (
    <Border>
      <Stripe />
      <CardNumber>
        <Flex center>{formatCardNumer(props.cardNumber)}</Flex>
      </CardNumber>

      <Expiration>
        <Flex crosscenter>
          <ExpLabel>Valid Thru</ExpLabel>
          <span>{props.cardExpire}</span>
          <Spacer />
          <ExpLabel>CCV Code</ExpLabel>
          <span>{props.cardSecurityCode}</span>
        </Flex>
      </Expiration>
      <Flex center>
        <CardHolder>{props.cardName}</CardHolder>
      </Flex>
    </Border>
  );
};

export default CreditCardDisplay;
