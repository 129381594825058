//import React from "react";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import "react-datepicker/dist/react-datepicker.css";
const CustomDatePicker = styled(DatePicker)`
  padding: 10px 15px;
  border: 1px solid #ccc;
  border-radius: 4px;

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: grey;
  }
`;

export default CustomDatePicker;
