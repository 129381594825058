const react = require("react");
export const createFieldState = (defaultValue, { validators, errorHookFn }) => {
  const [newField, setNewField] = react.useState(defaultValue);
  const [newFieldError, setNewFieldError] = react.useState(null);

  const errorWrap = errorFn => {
    if (errorHookFn) {
      return errorHookFn(errorFn);
    } else {
      return errorFn;
    }
  };

  return {
    get() {
      return newField;
    },
    set(value) {
      setNewField(value);
    },
    setError(errorMessage) {
      setNewFieldError(errorMessage);
    },
    getError() {
      return newFieldError;
    },
    hasError() {
      return newFieldError !== null;
    },
    validate() {
      if (validators) {
        for (let validator of validators) {
          if (!validator(newField, errorWrap(setNewFieldError))) {
            return false;
          }
        }
      }
      return true;
    }
  };
};

export const createLabeledField = (
  label,
  defaultValue,
  { validators, labelWidth, errorHookFn }
) => {
  const field = createFieldState(defaultValue, { validators, errorHookFn });
  return {
    ...field,
    params: {
      label,
      labelWidth,
      onTextChange: field.set,
      onValueChange: field.set,
      onBlur: field.validate,
      invalidValue: field.getError(),
      error: field.getError(),
      value: field.get()
    }
  };
};
