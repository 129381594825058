import React from "react";

const ErrorHandler = props => {
  try {
    return props.children;
  } catch (error) {
    console.error("There was an unexpected error");
    console.error(error);
  }
  return <>There was an error, please contact your administrator...</>;
};

export default ErrorHandler;
