import React from "react";
import styled from "styled-components";
import Moment from "react-moment";

const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  & > div {
    margin-left: 5px;
    margin-right: 5px;
  }

  border-bottom: 1px solid black;
  margin-top: 5px;
  margin-bottom: 5px;

  &:last-child {
    border-bottom: none;
  }
`;

const AdminVisited = props => {
  const { adminEmail, ipAddress, userAgent } = props.audit;
  return (
    <>
      <b>{adminEmail}</b> Viewied confirmation from <b>{ipAddress}</b> using
      useragent <b>{userAgent}</b>
    </>
  );
};

const CreatedNew = props => {
  const { adminEmail } = props.audit;
  return (
    <>
      <b>{adminEmail}</b> Created confirmation
    </>
  );
};

const AdminChangedStage = props => {
  const { adminEmail, oldState, newState } = props.audit;
  return (
    <>
      <b>{adminEmail}</b> changed state from <b>{oldState}</b> to{" "}
      <b>{newState}</b>
    </>
  );
};

const UserAction = props => {
  const { ipAddress, userAgent } = props.audit;
  return (
    <>
      User {props.details ? "provided payment details" : "visited confirmation"}{" "}
      from <b>{ipAddress}</b> using useragent <b>{userAgent}</b>
    </>
  );
};

const AuditItem = props => {
  const { eventType, time } = props.audit;
  let display = null;
  switch (eventType) {
    case "ADMIN_VISITED":
      display = <AdminVisited audit={props.audit} />;
      break;
    case "STATE_CHANGE":
      display = <AdminChangedStage audit={props.audit} />;
      break;
    case "NEW_CONFIRMATION":
      display = <CreatedNew audit={props.audit} />;
      break;
    case "CONFIRMATION_DETAILS_PROVIDED":
      display = <UserAction audit={props.audit} details={true} />;
      break;
    case "CONFIRMATION_VISITED":
      display = <UserAction audit={props.audit} details={false} />;
      break;
    default:
      display = <div>{eventType}</div>;
  }

  return (
    <ItemWrapper>
      <div>
        <Moment format={"YYYY-MM-DD hh-mm-ss A"}>{time}</Moment>
      </div>
      <div>{display}</div>
    </ItemWrapper>
  );
};

export default AuditItem;
