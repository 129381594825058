import React from "react";
//import styled from "styled-components";
import { LabeledTextControl } from "../components/LabeledControlsReloaded.js";
import { Flex, FlexVerticalIfMobile } from "../components/FlexComponents.js";

const {
  ValidateIsSupportedCreditCard,
  ValidateNotEmpty
} = require("../validators");

const CreditCardDetailsReloaded = props => {
  return (
    <>
      <Flex marginTop={5} marginBottom={5} vertical>
        <LabeledTextControl
          label={"Card Number"}
          desktopLabelWidth={"100px"}
          desktopControlWidth={"235px"}
          textFontWeight={"bold"}
          textFontFamily={'"Share Tech Mono", monospace'}
          creditcard
          onValueChange={value => props.cardNumberChange(value.value)}
          value={props.cardNumber}
          onBlur={() =>
            ValidateIsSupportedCreditCard(
              props.cardNumber,
              props.setCardNumberError
            )
          }
          error={props.cardNumberError}
        />
      </Flex>
      <FlexVerticalIfMobile>
        <Flex marginTop={5} marginBottom={5} marginRightDesktop={10} vertical>
          <LabeledTextControl
            label={"Expiry"}
            desktopLabelWidth="100px"
            desktopControlWidth={"90px"}
            format="##/##"
            placeholder="MM/YY"
            textFontWeight={"bold"}
            textFontFamily={'"Share Tech Mono", monospace'}
            formatted
            onValueChange={value =>
              props.cardExpireChange(value.formattedValue)
            }
            onBlur={() =>
              //TODO validate date
              ValidateNotEmpty(props.cardExpire, props.setCardExpireError)
            }
            error={props.cardExpireError}
            value={props.cardExpire}
          />
        </Flex>
        <Flex marginTop={5} marginBottom={5} vertical>
          <LabeledTextControl
            label={"CCV Code"}
            desktopLabelWidth="75px"
            desktopControlWidth={"60px"}
            format={val => (val.length > 4 ? val.substring(0, 4) : val)}
            placeholder="123"
            textFontWeight={"bold"}
            textFontFamily={'"Share Tech Mono", monospace'}
            onValueChange={value => props.cardSecurityCodeChange(value)}
            onBlur={() =>
              ValidateNotEmpty(
                props.cardSecurityCode,
                props.setCardSecurityCodeError
              )
            }
            error={props.cardSecurityCodeError}
            value={props.cardSecurityCode}
          />
        </Flex>
      </FlexVerticalIfMobile>
      <Flex marginTop={5} marginBottom={5} vertical>
        <LabeledTextControl
          label={"Name on Card"}
          desktopLabelWidth={"100px"}
          desktopControlWidth={"235px"}
          placeholder={"John Doe"}
          textFontWeight={"bold"}
          textFontFamily={'"Share Tech Mono", monospace'}
          onValueChange={value => props.cardNameChange(value)}
          onBlur={() =>
            ValidateNotEmpty(props.cardName, props.setCardNameError)
          }
          error={props.cardNameError}
          value={props.cardName}
        />
      </Flex>
    </>
  );
};

export default CreditCardDetailsReloaded;
