module.exports = {
  getMessageForState(state) {
    switch (state) {
      case "PENDING":
        return "Awaiting Hotel Verification";
      case "NEW":
        return "Waiting for Customer";
      default:
        return state;
    }
  }
};
