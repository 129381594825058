const { filteredFetch } = require("../errors");

const addUser = useObject => {
  return filteredFetch(`/api/admin/users`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify(useObject)
  }).then(res => res.json());
};

const getUsers = () => {
  return filteredFetch(`/api/admin/users`, {
    method: "GET",
    credentials: "include"
  }).then(res => res.json());
};

const getPermissions = () => {
  return filteredFetch(`/api/admin/users/permissions`, {
    method: "GET",
    credentials: "include"
  }).then(res => res.json());
};

const updateUser = (email, update) => {
  return filteredFetch(`/api/admin/users`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ email, update })
  }).then(res => res.json());
};

const deleteUser = email => {
  return filteredFetch(`/api/admin/users`, {
    method: "DELETE",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ email })
  }).then(res => res.json());
};

const login = (email, password) => {
  return filteredFetch(`/api/admin/users/login`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ username: email, password })
  }).then(res => res.json());
};

const getMe = () => {
  return filteredFetch(`/api/admin/users/me`, {
    method: "GET",
    credentials: "include"
  }).then(res => res.json());
};

module.exports = {
  addUser,
  getUsers,
  getPermissions,
  updateUser,
  login,
  getMe,
  deleteUser
};
