import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import styled from "styled-components";

import { Page as UserPage } from "./user/Page";
import { Page as AdminPage } from "./admin/Page";
import Home from "./user/Home";

import { FlexHorizontal } from "./components/FlexComponents";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone, faMapMarkerAlt } from "@fortawesome/free-solid-svg-icons";
import Button from "./components/button";
import Moment from "react-moment";
import ErrorHandler from "./components/ErrorHandler";
import { NotMobile, MobileOnly, DesktopOnly } from "./components/screensize";

library.add(faPhone);
library.add(faMapMarkerAlt);

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100%;
  //height: 100%; // breaks the fill height in ContentContainer
  align-items: center;
  background-color: #eee;
  font-family: "Roboto";
`;

const Header = styled.div`
  height: 50px;
  background-color: #1c1c1c;
  width: 100%;
  color: white;
`;

const HeaderContainer = styled.div`
  width: 85%;

  @media (max-width: 822px) {
    width: 100%;
  }
`;

const HeaderSection = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;
`;

const FooterSection = styled.div`
  margin-top: 15px;
  margin-left: 10px;
  margin-right: 10px;

  @media (max-width: 480px) {
    font-size: 74%;
  }

  @media (max-width: 412px) {
    font-size: 67%;
  }

  @media (max-width: 360px) {
    font-size: 55%;
  }
`;

const HeaderFooterTextSpan = styled.span`
  margin-right: 10px;
  margin-left: 10px;
`;

const HeaderRight = styled.div`
  margin-left: auto;
`;

const Footer = styled.div`
  height: 50px;
  background-color: #1c1c1c;
  width: 100%;
  color: white;
  font-size: 85%;
`;

const ContentContainer = styled.div`
  display: flex;
  width: 85%;
  background-color: #fff;
  margin-bottom: auto;
  flex: 1; //fixes the fill height issue
  border-left: 1px solid #ccc;
  border-right: 1px solid #ccc;

  //@media (max-width: 768px) {
  @media (max-width: 822px) {
    width: auto; //100%; -- fix for needing scroll bars because this div was a few pixes too big
    align-self: stretch;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 5px;
  flex: 2;
  //width: 100%;
`;

const LogoContainer = styled.div`
  display: flex;
  height: 100px;
  justify-content: center;
`;

class App extends Component {
  render() {
    return (
      <ErrorHandler>
        <MainContainer>
          <Header>
            <FlexHorizontal center>
              <HeaderContainer>
                <NotMobile>
                  <FlexHorizontal center>
                    <HeaderSection>
                      <FontAwesomeIcon icon="phone" flip="horizontal" />
                      <HeaderFooterTextSpan>
                        (+84) 235 3666 678
                      </HeaderFooterTextSpan>
                    </HeaderSection>
                    <HeaderSection>
                      <FontAwesomeIcon icon="map-marker-alt" />
                      <HeaderFooterTextSpan>
                        12 Nguyen Du Street, Cam Pho Ward, Hoi An City
                      </HeaderFooterTextSpan>
                    </HeaderSection>
                    <HeaderRight>
                      <Button
                        height={"50px"}
                        onClick={() =>
                          window.location.assign("http://lalunahoian.com")
                        }
                      >
                        Home
                      </Button>
                    </HeaderRight>
                  </FlexHorizontal>
                </NotMobile>
                <MobileOnly>
                  <LogoContainer>
                    <img src="/Laluna-Logo.png" height="49" alt={"logo"} />
                  </LogoContainer>
                </MobileOnly>
              </HeaderContainer>
            </FlexHorizontal>
          </Header>
          <ContentContainer>
            <ContentWrapper>
              <DesktopOnly>
                <LogoContainer>
                  <img src="/Laluna-Logo.png" height="100" alt={"logo"} />
                </LogoContainer>
              </DesktopOnly>
              <Router>
                <Switch>
                  <Route path="/" exact render={props => <Home {...props} />} />
                  <Route
                    path="/confirmation/:code"
                    render={props => <UserPage {...props} />}
                  />
                  <Route
                    path="/admin"
                    render={props => <AdminPage {...props} />}
                  />
                  <Redirect to="/" />
                </Switch>
              </Router>
            </ContentWrapper>
          </ContentContainer>
          <Footer>
            <FlexHorizontal center>
              <FooterSection>
                {"LALUNA HOI AN RIVERSIDE HOTEL & SPA "} <Moment format={"Y"} />
                {" © Copyright "}
                <Moment format={"Y"} /> {". All Rights Reserved."}
              </FooterSection>
            </FlexHorizontal>
          </Footer>
        </MainContainer>
      </ErrorHandler>
    );
  }
}

export default App;
