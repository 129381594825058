const { filteredFetch } = require("../errors");

const getConfirmations = () => {
  return filteredFetch("/api/admin/confirmations").then(res => res.json());
};

const getConfirmation = confirmationCode => {
  return filteredFetch(`/api/admin/confirmations/${confirmationCode}`, {
    method: "GET",
    credentials: "include"
  }).then(res => res.json());
};

const getAudits = confirmationCode => {
  return filteredFetch(`/api/admin/confirmations/${confirmationCode}/audits`, {
    method: "GET",
    credentials: "include"
  }).then(res => res.json());
};

const updateState = (confirmationCode, newState) => {
  return filteredFetch(`/api/admin/confirmations/${confirmationCode}`, {
    method: "PUT",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ state: newState })
  }).then(res => res.json());
};

module.exports = {
  getConfirmation,
  updateState,
  getConfirmations,
  getAudits
};
