const getPublicKey = () => {
  return fetch("/api/publicKey").then(res => res.text());
};

const sendCreditCardDetails = (code, ccObject) => {
  const JSEncrypt = require("jsencrypt");
  const cryptor = new JSEncrypt.JSEncrypt();

  return getPublicKey().then(res => {
    cryptor.setPublicKey(res);

    const data = JSON.stringify(ccObject);
    const encrypted = cryptor.encrypt(data);
    return fetch(`/api/confirmation/${code}`, {
      method: "PUT",
      headers: {
        "Content-Type": "text/plain"
        // "Content-Type": "application/x-www-form-urlencoded",
      },
      body: encrypted
    }).then(res => res.json());
  });
};

module.exports = {
  sendCreditCardDetails
};
