import React, { useState } from "react";
import ReservationDetails from "./ReservationDetails";
import TermsAndConditions from "./TermsAndConditions";

import {
  ValidateNotEmpty,
  ValidateIsSupportedCreditCard,
  ValidateIsNumber
} from "../validators";

import styled from "styled-components";

import Button from "../components/button";
import { FlexHorizontal } from "../components/FlexComponents";
import { sendCreditCardDetails } from "../api/user/confirmations";
import { ErrorModal } from "../components/modal";
import CreditCardDetailsReloaded from "./CreditCardDetailsReloaded";

const SectionWrapper = styled.div`
  padding: 20px;
  border-bottom: 2px solid #888;
`;

const NewConfirmation = props => {
  const [cardNumber, setCardNumber] = useState("");
  const [cardNumberError, setCardNumberError] = useState(null);

  const [cardName, setCardName] = useState("");
  const [cardNameError, setCardNameError] = useState(null);

  const [cardSecurityCode, setCardSecurityCode] = useState("");
  const [cardSecurityCodeError, setCardSecurityCodeError] = useState(null);

  const [cardExpire, setCardExpire] = useState("");
  const [cardExpireError, setCardExpireError] = useState(null);

  const [termsChecked, setTermsChecked] = useState(false);
  const [confirmChecked, setConfirmChecked] = useState(false);

  const [validationError, setValidationError] = useState(null);

  const Validate = () => {
    const errors = [];
    const fnError = fn => {
      return message => {
        message && errors.push(message);
        fn(message);
      };
    };
    ValidateIsSupportedCreditCard(cardNumber, fnError(setCardNumberError));

    ValidateNotEmpty(cardName, fnError(setCardNameError));

    ValidateNotEmpty(cardSecurityCode, fnError(setCardSecurityCodeError));
    ValidateIsNumber(cardSecurityCode, fnError(setCardSecurityCodeError));

    ValidateNotEmpty(cardExpire, fnError(setCardExpireError));
    //TODO: VALIDATE DATE

    if (errors.length > 0) {
      setValidationError(
        "There is an issue with the information provided, please fix the indicated fields before submitting."
      );
      return false;
    }

    if (!termsChecked) {
      setValidationError(
        "You must agree to the terms and conditions before submitting."
      );
      return false;
    }

    if (!confirmChecked) {
      setValidationError(
        "You must verify the reservation details before submitting."
      );
      return false;
    }
    return true;
  };

  const sendDetails = () => {
    if (Validate()) {
      sendCreditCardDetails(props.confirmationCode, {
        cardNumber,
        cardName,
        cardSecurityCode,
        cardExpire
      }).then(res => props.refresh());
    }
  };

  return (
    <>
      <SectionWrapper>
        <ReservationDetails
          reservation={props.reservation.reservationNumber}
          guests={props.reservation.numberGuests}
          checkIn={props.reservation.checkIn}
          checkOut={props.reservation.checkOut}
          roomDescription={props.reservation.description}
          cost={props.reservation.cost}
          deposit={props.reservation.deposit}
          refundable={props.reservation.refundable}
        />
      </SectionWrapper>
      <SectionWrapper>
        <CreditCardDetailsReloaded
          cardNumber={cardNumber}
          cardExpire={cardExpire}
          cardSecurityCode={cardSecurityCode}
          cardName={cardName}
          /* change handlers */
          cardNumberChange={value => setCardNumber(value)}
          cardNameChange={value => setCardName(value)}
          cardSecurityCodeChange={value => setCardSecurityCode(value)}
          cardExpireChange={value => setCardExpire(value)}
          /* error handlers */
          setCardNumberError={setCardNumberError}
          cardNumberError={cardNumberError}
          setCardExpireError={setCardExpireError}
          cardExpireError={cardExpireError}
          setCardSecurityCodeError={setCardSecurityCodeError}
          cardSecurityCodeError={cardSecurityCodeError}
          setCardNameError={setCardNameError}
          cardNameError={cardNameError}
        />
      </SectionWrapper>
      <SectionWrapper>
        <TermsAndConditions
          deposit={props.reservation.deposit}
          cost={props.reservation.cost}
          refundable={props.reservation.refundable}
          termsChecked={termsChecked}
          confirmChecked={confirmChecked}
          confirmCheckChange={value => setConfirmChecked(value)}
          termCheckChange={value => setTermsChecked(value)}
          refundableTimeFrame={props.reservation.refundableTimeFrame || "3"}
        />
      </SectionWrapper>
      <SectionWrapper>
        <FlexHorizontal center>
          <Button onClick={() => sendDetails()}>Submit</Button>
        </FlexHorizontal>
      </SectionWrapper>
      {validationError && (
        <ErrorModal
          error={validationError}
          onDismiss={() => setValidationError(null)}
        />
      )}
    </>
  );
};

export default NewConfirmation;
