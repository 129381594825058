import React from "react";
import styled from "styled-components";
import TextBox, { Password } from "./textbox";
import { FlexVerticalIfMobile, Flex } from "./FlexComponents";
import CustomNumberFormat from "./NumberFormat";
//import CustomDatePicker from "./datepicker";
import CreditCardTextBox from "./CreditCardTextBox";

const StyledLabelContainer = styled.div`
  font-size: 80%;
  font-weight: bold;
  display: flex;
  align-items: center;

  @media (min-width: 768px) {
    font-size: 90%;
    ${props => !props.width && "margin-right: 10px;"}
    ${props => props.width && `width: ${props.width};`}
  }
`;

const StyledError = styled.span`
  font-size: 75%;
  color: red;
`;

/*
  one control for all text types password, text area, text box, formated number, date
 */
export const TextControl = props => {
  const {
    formatted,
    textarea,
    creditcard,
    date,
    password,
    onChange,
    onValueChange,
    format,
    ...cleanProps
  } = props;
  if (formatted) {
    return <CustomNumberFormat format={format} {...cleanProps} onValueChange={onValueChange}/>;
  } else if (textarea) {
    //TODO: text area
  } else if (creditcard) {
    return <CreditCardTextBox {...cleanProps} onValueChange={onValueChange}/>;
  } else if (date) {
    //TODO: date
  } else if (password) {
    return (
      <Password
        {...cleanProps}
        onChange={event => onValueChange && onValueChange(event.target.value)}
      />
    );
  } else {
    return (
      <TextBox
        {...cleanProps}
        onChange={event => onValueChange && onValueChange(event.target.value)}
      />
    );
  }
};

export const LabeledTextControl = props => {
  const {
    onValueChange, // we will only use this for now
    onTextChange, // remove to prevent errors -- no longer used
    onChange, //remove to prevent errors -- dont want this
    invalidValue, //no longer used
    error,
    onBlur,
    textFontWeight,
    textFontFamily,
    label,
    desktopLabelWidth,
    desktopControlWidth,
    ...cleanedProps
  } = props;

  return (
    <>
      <Flex vertical>
        <FlexVerticalIfMobile>
          <StyledLabelContainer width={desktopLabelWidth}>
            {label}
          </StyledLabelContainer>
          <Flex desktop width={desktopControlWidth} /* flex={"1"}*/>
            <TextControl
              onValueChange={onValueChange}
              error={error}
              invalidValue={error}
              onBlur={onBlur}
              fontWeight={textFontWeight}
              fontFamily={textFontFamily}
              width={"100%"}
              {...cleanedProps}
            />
          </Flex>
          <Flex mobile vertical>
            <Flex>
              <TextControl
                onValueChange={onValueChange}
                error={error}
                invalidValue={error}
                onBlur={onBlur}
                fontWeight={textFontWeight}
                fontFamily={textFontFamily}
                {...cleanedProps}
              />
            </Flex>
          </Flex>
        </FlexVerticalIfMobile>
        {props.error && <StyledError>{props.error}</StyledError>}
      </Flex>
    </>
  );
};
