import React from "react";
import { LabledTextBox } from "../components/LabeledControls";
import Button from "../components/button";
import { createLabeledField } from "../lib/field";
import { ValidateNotEmpty } from "../validators";
import { addUser } from "../api/admin/users";
import { Flex } from "../components/FlexComponents";

const NewUserForm = props => {
  const errors = [];

  const errorHook = errorFn => {
    return message => {
      message && errors.push(message);
      errorFn(message);
    };
  };

  const email = createLabeledField("Email", "", {
    validators: [ValidateNotEmpty],
    errorHookFn: errorHook
  });

  const firstName = createLabeledField("First Name", "", {
    validators: [ValidateNotEmpty],
    errorHookFn: errorHook
  });

  const lastName = createLabeledField("Last Name", "", {
    validators: [ValidateNotEmpty],
    errorHookFn: errorHook
  });

  const password = createLabeledField("Password", "", {
    validators: [ValidateNotEmpty],
    errorHookFn: errorHook
  });

  const confirmPassword = createLabeledField("Confirm Password", "", {
    validators: [ValidateNotEmpty],
    errorHookFn: errorHook
  });

  const validateAll = () => {
    email.validate();
    firstName.validate();
    lastName.validate();
    password.validate();
    confirmPassword.validate();

    if (password.get() !== confirmPassword.get()) {
      confirmPassword.setError("The passwords do not match");
      return false;
    }
    if (errors.length > 0) {
      return false;
    }
    return true;
  };

  const sendNewUser = () => {
    if (validateAll()) {
      console.log("Send");
      addUser({
        email: email.get(),
        firstName: firstName.get(),
        lastName: lastName.get(),
        password: password.get()
      }).then(res => props.reload());
    }
  };

  return (
    <Flex marginTop={20} vertical>
      New User
      <LabledTextBox {...email.params} />
      <LabledTextBox {...firstName.params} />
      <LabledTextBox {...lastName.params} />
      <LabledTextBox {...password.params} password />
      <LabledTextBox {...confirmPassword.params} password />
      <Flex>
        <Button onClick={sendNewUser}>Add User</Button>
      </Flex>
    </Flex>
  );
};

export default NewUserForm;
