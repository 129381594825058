import React from "react";
import styled from "styled-components";

import CustomNumberFormat from "./NumberFormat";
import VisaSvg from "../Visa.svg";
import MasterCardSvg from "../MasterCard.svg";

const getCardImage = cardnumber => {
  if (cardnumber) {
    if (cardnumber[0] && cardnumber[0] === "5" && cardnumber[1]) {
      if (
        cardnumber[1] === "0" ||
        cardnumber[1] === "1" ||
        cardnumber[1] === "2" ||
        cardnumber[1] === "3" ||
        cardnumber[1] === "4" ||
        cardnumber[1] === "5"
      ) {
        return MasterCardSvg;
      }
    } else if (cardnumber[0] && cardnumber[0] === "4") {
      return VisaSvg;
    }
  }
  return "";
};

/*<StyledLabeledCustomNumberFormat
{...cleanedProps}
onValueChange={newValue => onValueChange && onValueChange(newValue)}
onBlur={onBlur}
fontWeight={textFontWeight}
fontFamily={textFontFamily}
format={"#### #### #### ####"}
placeholder={"#### #### #### ####"}
/>*/
/*
const CCLogo = styled.div`
  ${props => props.image && `background-image: url("${props.image}");`}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 50px;
  height: 35px;
  background-color: white;
`;*/
const StyledCustomNumberFormat = styled(CustomNumberFormat)`
  background-image: url(${props => getCardImage(props.value)});
  background-size: auto 70%;
  background-repeat: no-repeat;
  background-position: right center;
`;

const CreditCardTextBox = props => {
  return (
    <StyledCustomNumberFormat
      placeholder={"#### #### #### ####"}
      fontWeight={props.fontWeight}
      fontFamily={props.fontFamily}
      invalidValue={props.invalidValue}
      value={props.value}
      format={"#### #### #### ####"}
      onValueChange={newValue =>
        props.onValueChange && props.onValueChange(newValue)
      }
      onBlur={props.onBlur}
    />
  );
};

export default CreditCardTextBox;
