import React from "react";

import styled, { keyframes } from "styled-components";
import { FlexHorizontal, FlexVertical } from "./FlexComponents";
import Button from "./button";

const StyledModal = styled.div`
  position: fixed;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  overflow: auto;
  color: white;
`;

const FullHeightFlexHorizontal = styled(FlexHorizontal)`
  height: 100%;
`;

const LoadingKeyFrames = keyframes`
    0% {
        font-size: 200%;
    }
    50% {
        font-size: 250%;
    }
    100% {
        font-size: 200%;
    }
`;

const LoadingMessageSpan = styled.span`
  font-size: 200%;
  animation: ${LoadingKeyFrames} 1s ease-in-out 0s infinite;
`;

export const Modal = props => {
  return <StyledModal>{props.children}</StyledModal>;
};

export const LoadingModal = props => {
  return (
    <Modal>
      <FullHeightFlexHorizontal center crosscenter>
        <LoadingMessageSpan>Loading...</LoadingMessageSpan>
      </FullHeightFlexHorizontal>
    </Modal>
  );
};

const StyledMessageBoxDiv = styled.div`
  background-color: white;
  color: black;
  border: 2px solid #ccc;
  ${props => props.width && `width: ${props.width};`}
  ${props => props.height && `height: ${props.height};`}
  ${props => props.padding && `padding: ${props.padding};`}
`;

const StyledErrorMessageSpan = styled.span`
  margin: 15px;
`;

export const ErrorModal = props => {
  return (
    <Modal>
      <FullHeightFlexHorizontal center crosscenter>
        <StyledMessageBoxDiv padding="10px 15px">
          <FlexVertical crosscenter>
            <StyledErrorMessageSpan>{props.error}</StyledErrorMessageSpan>
            <FlexHorizontal crosscenter>
              <Button onClick={() => props.onDismiss && props.onDismiss()}>
                OK
              </Button>
            </FlexHorizontal>
          </FlexVertical>
        </StyledMessageBoxDiv>
      </FullHeightFlexHorizontal>
    </Modal>
  );
};

export const MessageModal = props => {
  return (
    <Modal>
      <FullHeightFlexHorizontal center crosscenter>
        <StyledMessageBoxDiv padding="10px 15px">
          <FlexVertical crosscenter>
            <StyledErrorMessageSpan>{props.message}</StyledErrorMessageSpan>
            <FlexHorizontal crosscenter>
              <Button onClick={() => props.onDismiss && props.onDismiss()}>
                OK
              </Button>
            </FlexHorizontal>
          </FlexVertical>
        </StyledMessageBoxDiv>
      </FullHeightFlexHorizontal>
    </Modal>
  );
};

export const ContinueModal = props => {
  const Spacer = styled.div`
    width: 10px;
  `;

  return (
    <Modal>
      <FullHeightFlexHorizontal center crosscenter>
        <StyledMessageBoxDiv padding="10px 15px">
          <FlexVertical crosscenter>
            <StyledErrorMessageSpan>{props.message}</StyledErrorMessageSpan>
            <FlexHorizontal crosscenter>
              <Button onClick={() => props.onCancel && props.onCancel()}>
                Cancel
              </Button>
              <Spacer />
              <Button onClick={() => props.onContinue && props.onContinue()}>
                Continue
              </Button>
            </FlexHorizontal>
          </FlexVertical>
        </StyledMessageBoxDiv>
      </FullHeightFlexHorizontal>
    </Modal>
  );
};
