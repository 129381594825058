import React, { useState } from "react";
//import styled from "styled-components";

import NewConfirmation from "./NewConfirmation";
import ConfirmationStatus from "./ConfirmationStatus";

export const Page = props => {
  const [confirmation, setConfirmation] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  const loadConfirmation = () => {
    console.log(`/api/confirmation/${props.match.params.code}`);
    fetch(`/api/confirmation/${props.match.params.code}`)
      .then(res => {
        if (res.status !== 200) {
          props.history.push("/");
        }
        return res;
      })
      .then(res => res.json())
      .then(res => {
        setConfirmation(res);
        setIsLoading(false);
        return res;
      })
      .catch(error => console.log(error));
  };

  if (!confirmation) {
    loadConfirmation();
  }

  if (isLoading) {
    return "Loading...";
  } else {
    switch (confirmation.state) {
      case "NEW":
        return (
          <NewConfirmation
            confirmationCode={props.match.params.code}
            reservation={confirmation.reservation}
            refresh={() => loadConfirmation()}
          />
        );
      default:
        return <ConfirmationStatus status={confirmation.state} />;
    }
  }
};
