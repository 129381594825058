import React from "react";

import styled from "styled-components";
import { Flex, FlexVerticalIfMobile } from "../components/FlexComponents";
import Checkbox from "../components/checkbox";
import Button from "../components/button";

const Wrapper = styled(Flex)`
  border: 1px solid #ccc;
  border-bottom: 0px;
  padding: 10px;
  ${props => props.last && "border-bottom: 1px solid #ccc;"}
`;

const PermLabel = styled.span`
  font-size: 75%;
  font-weight: bold;
`;

const UserEntry = props => {
  const hasPermission = value => {
    for (let perm of props.permissions) {
      if (perm === value) {
        return true;
      }
    }
    return false;
  };

  const getPermissionName = value => {
    for (let aperm of props.avilablePermissions) {
      if (aperm.value === value) {
        return aperm.name;
      }
    }
    return "NA";
  };

  const _changed = name => checked => {
    if (props.updatePermissions) {
      if (checked) {
        const newPermissions = props.permissions;
        newPermissions.push(name);
        props.updatePermissions(newPermissions);
      } else {
        const newPermissions = [];
        for (let perm of props.permissions) {
          if (perm !== name) {
            newPermissions.push(perm);
          }
        }
        props.updatePermissions(newPermissions);
      }
    }
  };

  /*const _changed = event => {
    if (props.updatePermissions) {
      if (event.target.checked) {
        const newPermissions = props.permissions;
        newPermissions.push(event.target.name);
        props.updatePermissions(newPermissions);
      } else {
        const newPermissions = [];
        for (let perm of props.permissions) {
          if (perm !== event.target.name) {
            newPermissions.push(perm);
          }
        }
        props.updatePermissions(newPermissions);
      }
    }
  };*/

  return (
    <Wrapper vertical last={props.last}>
      <FlexVerticalIfMobile>
        <Flex vertical>
          <Flex>{`${props.firstName} ${props.lastName}`}</Flex>
          <Flex>{props.email}</Flex>
        </Flex>
        <FlexVerticalIfMobile marginTop={10} marginLeftDesktop={10}>
          {props.avilablePermissions.map((item, i) => (
            /*<input
              key={i}
              type="checkbox"
              checked={hasPermission(item.value)}
              onChange={_changed}
              name={item.value}
            />*/
            <Flex key={i}>
              <Flex marginRight={5} marginLeft={5}>
                <Checkbox
                  onChange={_changed(item.value)}
                  checked={hasPermission(item.value)}
                />
              </Flex>
              <Flex marginRight={5}>
                <PermLabel>{getPermissionName(item.value)}</PermLabel>
              </Flex>
            </Flex>
          ))}
        </FlexVerticalIfMobile>
        <div>
          <Button onClick={props.deleteUser}>Delete</Button>
        </div>
      </FlexVerticalIfMobile>
    </Wrapper>
    /*<>
      <FlexHorizontal>
        <div>{props.email}</div>
        {props.avilablePermissions.map(item => (
          <input
            type="checkbox"
            checked={hasPermission(item.value)}
            onChange={_changed}
            name={item.value}
          />
        ))}
      </FlexHorizontal>
      {props.firstName}
      {props.lastName}
    </>*/
  );
};

export default UserEntry;
