const allow = (user, permission) => {
  if (
    user &&
    user.permissions &&
    user.permissions.some(r => r === permission)
  ) {
    return true;
  }
  return false;
};

module.exports = {
  allow
};
