const ValidateNotEmpty = (value, fn) => {
  if (value.length > 0) {
    fn(null);
    return true;
  } else {
    fn("A value must be provided");
    return false;
  }
};

const ValidateIsNumber = (value, fn) => {
  if (isNaN(parseInt(value, 10))) {
    fn("A numeric value must be provided");
    return false;
  }
  fn(null);
  return true;
};

const ValidateIsSupportedCreditCard = (value, fn) => {
  if (value) {
    if (value[0] && value[0] === "5" && value[1]) {
      if (
        value[1] === "0" ||
        value[1] === "1" ||
        value[1] === "2" ||
        value[1] === "3" ||
        value[1] === "4" ||
        value[1] === "5"
      ) {
        if (value.length === 16) {
          fn(null);
          return true;
        }
      }
    } else if (value[0] && value[0] === "4") {
      if (value.length === 16) {
        fn(null);
        return true;
      }
    }
  }
  fn(
    "Invalid or unsupported credit card, please enter either a Visa or MasterCard number"
  );
  return false;
};

//const ValidateExpiration = (value, fn) => {};

module.exports = {
  ValidateIsNumber,
  ValidateIsSupportedCreditCard,
  ValidateNotEmpty
};
