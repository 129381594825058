import React, { useState } from "react";

//import styled from "styled-components";
import UserEntry from "./UserEntry";
import NewUserForm from "./NewUser";
import {
  getUsers,
  getPermissions,
  updateUser,
  deleteUser
} from "../api/admin/users";

const UserAdmin = props => {
  const [users, setUsers] = useState(null);
  const [permissions, setPermissions] = useState(null);

  const loadUsers = () => {
    getUsers()
      .then(res => setUsers(res))
      .catch(err => console.log(err));
    getPermissions()
      .then(res => setPermissions(res))
      .catch(err => console.log(err));
  };

  if (!users) {
    loadUsers();
  }

  //props.history.push("/admin");
  return (
    <div>
      Users
      {users &&
        permissions &&
        users.map((item, i) => (
          <UserEntry
            key={i}
            {...item}
            avilablePermissions={permissions}
            updatePermissions={newPermissions => {
              updateUser(item.email, { permissions: newPermissions }).then(
                res => loadUsers()
              );
            }}
            last={i === users.length - 1}
            deleteUser={() => {
              deleteUser(item.email).then(res => loadUsers());
            }}
          />
        ))}
      <NewUserForm reload={loadUsers} />
    </div>
  );
};

export default UserAdmin;
