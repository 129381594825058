//import React from "react";

import styled from "styled-components";

export const Flex = styled.div`
display: flex;
${props => props.vertical && "flex-direction: column;"}
${props => props.center && "justify-content: center;"}
${props => props.crosscenter && "align-items: center;"}
${props => props.space && "justify-content: space-around;"}
${props => props.width && `width: ${props.width};`}
${props => props.flex && `flex: ${props.flex};`}
${props => props.textcenter && "text-align: center;"}

${props => props.marginTop && "margin-top:" + props.marginTop + "px;"}
${props => props.marginBottom && "margin-bottom:" + props.marginBottom + "px;"}
${props => props.marginLeft && "margin-left:" + props.marginLeft + "px;"}
${props => props.marginRight && "margin-right:" + props.marginRight + "px;"}

${props =>
  props.marginLeftDesktop &&
  ` @media (min-width: 769px) {margin-left: ${props.marginLeftDesktop}px;}`}
${props =>
  props.marginRightDesktop &&
  ` @media (min-width: 769px) {margin-right: ${props.marginRightDesktop}px;}`}
${props =>
  props.marginTopDesktop &&
  ` @media (min-width: 769px) {margin-top: ${props.marginTopDesktop}px;}`}
${props =>
  props.marginBottomDesktop &&
  ` @media (min-width: 769px) {margin-bottom: ${
    props.marginBottomDesktop
  }px;}`}    

  ${props =>
    props.marginRightMobile &&
    ` @media (max-width: 768px) {margin-right: ${props.marginRightMobile}px;}`}
  ${props =>
    props.marginLeftMobile &&
    ` @media (max-width: 768px) {margin-left: ${
      props.marginRigmarginLeftMobilehtMobile
    }px;}`}
  ${props =>
    props.marginTopMobile &&
    ` @media (max-width: 768px) {margin-top: ${props.marginTopMobile}px;}`}
  ${props =>
    props.marginBottomMobile &&
    ` @media (max-width: 768px) {margin-bottom: ${
      props.marginBottomMobile
    }px;}`}

${props => {
  if (props.mobile) {
    return `
    display: none;

    @media (max-width: 768px) {
      display: flex;
    }`;
  } else if (props.desktop) {
    return `
    display: flex;

    @media (max-width: 768px) {
      display: none;
    }`;
  }
}}

`;

export const FlexVertical = styled.div`
  display: flex;
  flex-direction: column;
  ${props => props.center && "justify-content: center;"}
  ${props => props.crosscenter && "align-items: center;"}
  ${props => props.space && "justify-content: space-around;"}
`;

export const FlexHorizontal = styled.div`
  display: flex;
  ${props => props.center && "justify-content: center;"}
  ${props => props.crosscenter && "align-items: center;"}
  ${props => props.space && "justify-content: space-around;"}
`;

export const FlexVerticalIfMobile = styled(Flex)`
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;
