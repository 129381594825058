import React from "react";

import styled from "styled-components";
import { Flex } from "../components/FlexComponents";
import Button from "../components/button";
import { createLabeledField } from "../lib/field";
import { login } from "../api/admin/users";
import { LabeledTextControl } from "../components/LabeledControlsReloaded";
const queryString = require("query-string");

const LoginForm = props => {
  const email = createLabeledField("Email", "", {});
  const password = createLabeledField("Password", "", {});
  const query = queryString.parse(props.location.search);
  if (props.me !== null) {
    //need to do this to schedule the page change after load
    setTimeout(() => props.history.push("/admin"), 100);
  }
  const doLogin = () => {
    login(email.get(), password.get()).then(res => {
      if (res.success) {
        props.history.push(query.redirect || "/admin");
      } else {
        email.set("");
        password.set("");
      }
    });
  };
  return (
    <Flex vertical>
      <Flex vertical marginTop={5} marginBottom={5}>
        <LabeledTextControl {...email.params} desktopLabelWidth={"80px"} />
      </Flex>
      <Flex vertical marginTop={5} marginBottom={5}>
        <LabeledTextControl
          {...password.params}
          desktopLabelWidth={"80px"}
          onKeyPress={event => event.key === "Enter" && doLogin()}
          password
        />
      </Flex>
      <Flex vertical marginTop={5} marginBottom={5} crosscenter>
        <Button onClick={doLogin}>Login</Button>
      </Flex>
    </Flex>
  );
};

const Title = styled.div`
  font-size: 200%;
  font-weight: bold;
  margin-bottom: 50px;

  @media (max-width: 414px) {
    font-size: 150%;
  }
`;

const Login = props => {
  //props.history.push("/admin");
  const loginForm = <LoginForm {...props} />;
  return (
    <>
      <Flex center marginTop={20} marginBottom={20} textcenter>
        <Title>Payment Administration</Title>
      </Flex>
      <Flex desktop center>
        {loginForm}
      </Flex>
      <Flex mobile vertical>
        {loginForm}
      </Flex>
    </>
  );
};

export default Login;
