// eslint-disable-next-line
import React, { Component } from "react";
import { Link } from "react-router-dom";

import styled from "styled-components";

import { getMessageForState } from "../lib/states";
import { FlexHorizontal } from "../components/FlexComponents";

const StyledLink = styled(Link)`
  color: #a08036;
  text-decoration: none;
  font-weight: bold;
  &:visited {
    color: #a08036;
  }
  &:hover {
    color: #603003;
  }
`;

const Row = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-top: 10px;
  padding-bottom: 10px;
  font-size: 85%;
  &:hover {
    background-color: #eee;
  }
`;

const Header = styled.div`
  display: flex;
  border-bottom: 2px solid #ccc;
  padding-top: 10px;
  font-size: 85%;
  font-weight: bold;
`;

const RightMargin = styled.div`
  margin-right: 10px;
`;

const Col = styled.div`
  ${props =>
    props.minWidth && `min-width: ${props.minWidth}; width: ${props.minWidth};`}
  ${props => props.fillMargin && "margin-left: auto;"}
`;

export const ConfirmationListItemHeader = props => {
  return (
    <Header>
      <Col minWidth={"150px"}>
        <FlexHorizontal center>Last Name</FlexHorizontal>
      </Col>
      <Col minWidth={"150px"}>
        <FlexHorizontal center>Reservation</FlexHorizontal>
      </Col>
      <Col minWidth={"100px"}>
        <FlexHorizontal center>Confirmation</FlexHorizontal>
      </Col>
      <Col minWidth={"250px"}>
        <FlexHorizontal center>State</FlexHorizontal>
      </Col>
      <Col fillMargin />
    </Header>
  );
};

const ConfirmationListItem = props => {
  //console.log(props);
  return (
    <Row>
      <Col minWidth={"150px"}>
        <FlexHorizontal center>{props.lastName}</FlexHorizontal>
      </Col>
      <Col minWidth={"150px"}>
        <FlexHorizontal center>{props.reservationNumber}</FlexHorizontal>
      </Col>
      <Col minWidth={"100px"}>
        <FlexHorizontal center>{props.confirmationCode}</FlexHorizontal>
      </Col>
      <Col minWidth={"250px"}>
        <FlexHorizontal center>
          {getMessageForState(props.state)}
        </FlexHorizontal>
      </Col>
      <Col fillMargin>
        <RightMargin>
          <StyledLink to={`/admin/${props.confirmationCode}`}>View</StyledLink>
        </RightMargin>
      </Col>
    </Row>
  );
};

export default ConfirmationListItem;
