import React from "react";
import styled from "styled-components";
import TextBox, { Password } from "./textbox";
import { FlexHorizontal, FlexVertical } from "./FlexComponents";
import CustomNumberFormat from "./NumberFormat";
import VisaSvg from "../Visa.svg";
import MasterCardSvg from "../MasterCard.svg";
import CustomDatePicker from "./datepicker";

const StyledLabel = styled.label`
  padding: 10px 0px;
  font-size: 85%;
  margin: 0px 5px;
  ${props => props.width && "width: " + props.width + ";"}
`;

const LabeledFieldStyledWrapper = styled.div`
  border: 1px solid #ccc;
  //border-right: 0px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: #eee;

  ${props => props.invalidValue && "border-color: red;"}
`;

const StyledLabledTextBox = styled(TextBox)`
  border-bottom: 0px;
  border-top: 0px;
  border-right: 0px;
`;

const StyledLabledPassword = styled(Password)`
  border-bottom: 0px;
  border-top: 0px;
  border-right: 0px;
`;

const StyledLabeledCustomNumberFormat = styled(CustomNumberFormat)`
  border-bottom: 0px;
  border-top: 0px;
  border-right: 0px;
`;

const StyledError = styled.span`
  font-size: 75%;
  color: red;
`;

export const LabeledField = props => {
  return (
    <>
      {!props.dontIncludeFlex ? (
        <FlexVertical>
          <FlexHorizontal>
            <LabeledFieldStyledWrapper invalidValue={props.invalidValue}>
              <StyledLabel width={props.labelWidth}>{props.label}</StyledLabel>
              {props.children}
            </LabeledFieldStyledWrapper>
          </FlexHorizontal>
          {props.error && <StyledError>{props.error}</StyledError>}
        </FlexVertical>
      ) : (
        <FlexVertical>
          <LabeledFieldStyledWrapper invalidValue={props.invalidValue}>
            <StyledLabel width={props.labelWidth}>{props.label}</StyledLabel>
            {props.children}
          </LabeledFieldStyledWrapper>
          {props.error && <StyledError>{props.error}</StyledError>}
        </FlexVertical>
      )}
    </>
  );
};

export const LabeledNumberFormat = props => {
  const {
    onValueChange,
    onChange,
    invalidValue,
    error,
    onBlur,
    textFontWeight,
    textFontFamily,
    labelWidth,
    ...cleanedProps
  } = props;
  return (
    <LabeledField
      labelWidth={labelWidth}
      {...cleanedProps}
      invalidValue={invalidValue}
      error={error}
    >
      <StyledLabeledCustomNumberFormat
        {...cleanedProps}
        onValueChange={value => onValueChange && onValueChange(value)}
        onBlur={onBlur}
        fontWeight={textFontWeight}
        fontFamily={textFontFamily}
      />
    </LabeledField>
  );
};

const getCardImage = cardnumber => {
  if (cardnumber) {
    if (cardnumber[0] && cardnumber[0] === "5" && cardnumber[1]) {
      if (
        cardnumber[1] === "0" ||
        cardnumber[1] === "1" ||
        cardnumber[1] === "2" ||
        cardnumber[1] === "3" ||
        cardnumber[1] === "4" ||
        cardnumber[1] === "5"
      ) {
        return MasterCardSvg;
      }
    } else if (cardnumber[0] && cardnumber[0] === "4") {
      return VisaSvg;
    }
  }
  return "";
};

const CCLogo = styled.div`
  ${props => props.image && `background-image: url("${props.image}");`}
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left center;
  width: 50px;
  height: 35px;
  background-color: white;
`;

const CCSpacer = styled.div`
  width: 10px;
  height: 35px;
  border-radius: 0px 4px 4px 0px;
  background-color: white;
`;

export const LabeledCreditCardField = props => {
  const {
    onValueChange,
    onChange,
    invalidValue,
    error,
    onBlur,
    textFontWeight,
    textFontFamily,
    labelWidth,
    image,
    format,
    placeholder,
    value,
    ...cleanedProps
  } = props;
  return (
    <LabeledField
      labelWidth={labelWidth}
      {...cleanedProps}
      invalidValue={invalidValue}
      error={error}
    >
      <StyledLabeledCustomNumberFormat
        {...cleanedProps}
        onValueChange={newValue => onValueChange && onValueChange(newValue)}
        onBlur={onBlur}
        fontWeight={textFontWeight}
        fontFamily={textFontFamily}
        format={"#### #### #### ####"}
        placeholder={"#### #### #### ####"}
      />
      <CCLogo image={getCardImage(value)} />
      <CCSpacer />
    </LabeledField>
  );
};

export const LabledTextBox = props => {
  const {
    onValueChange,
    onTextChange,
    onChange,
    invalidValue,
    error,
    onBlur,
    textFontWeight,
    textFontFamily,
    ...cleanedProps
  } = props;
  return (
    <LabeledField {...cleanedProps} invalidValue={invalidValue} error={error}>
      {props.password ? (
        <StyledLabledPassword
          {...cleanedProps}
          onChange={event => onTextChange && onTextChange(event.target.value)}
          onBlur={onBlur}
          fontWeight={textFontWeight}
          fontFamily={textFontFamily}
        />
      ) : (
        <StyledLabledTextBox
          {...cleanedProps}
          onChange={event => onTextChange && onTextChange(event.target.value)}
          onBlur={onBlur}
          fontWeight={textFontWeight}
          fontFamily={textFontFamily}
        />
      )}
    </LabeledField>
  );
};

export const LabeledDatePicker = props => {
  const {
    onValueChange,
    onChange,
    invalidValue,
    error,
    onBlur,
    textFontWeight,
    textFontFamily,
    value,
    ...cleanedProps
  } = props;
  return (
    <LabeledField {...cleanedProps} invalidValue={invalidValue} error={error}>
      <CustomDatePicker
        onChange={onValueChange}
        selected={value || new Date()}
      />
    </LabeledField>
  );
};

export const LabeledRadioOption = props => {
  const { options, name, onValueChange, value, ...cleanedProps } = props;

  return (
    <LabeledField {...cleanedProps}>
      {options.map((item, index) => (
        <div key={`${index}-div`}>
          <input
            type="radio"
            name={name}
            value={item.value}
            onChange={event =>
              onValueChange && onValueChange(event.target.value)
            }
            checked={value === item.value}
          />
          <StyledLabel>{item.displayValue}</StyledLabel>
        </div>
      ))}
    </LabeledField>
  );
};

//TODO: Move this and make it more generic
const StyledTextArea = styled.textarea`
  padding: 10px 15px;
  border: none;
  border-top: 1px solid #ccc;
  border-radius: 4px;
  min-height: 20px;
  min-width: 50px;
  font-family: "Roboto";

  &:focus {
    outline: none;
  }

  &::placeholder {
    color: grey;
  }
`;

export const LabeledTextArea = props => {
  const {
    onValueChange,
    rows,
    cols,
    onTextChange,
    onBlur,
    invalidValue,
    error,
    value,
    ...cleanedProps
  } = props;
  return (
    <>
      <FlexHorizontal>
        <LabeledFieldStyledWrapper
          invalidValue={props.invalidValue}
          {...cleanedProps}
        >
          <FlexVertical>
            <StyledLabel width={props.labelWidth}>{props.label}</StyledLabel>
            <StyledTextArea
              rows={rows}
              cols={cols}
              onChange={event => {
                return onTextChange && onTextChange(event.target.value);
              }}
              onBlur={onBlur}
              {...cleanedProps}
            />
          </FlexVertical>
        </LabeledFieldStyledWrapper>
      </FlexHorizontal>
      {props.error && <StyledError>{props.error}</StyledError>}
    </>
  );
};
