// eslint-disable-next-line
import React, { Component } from "react";
import Moment from "react-moment";
import "moment-timezone";

import styled from "styled-components";
import NumberFormat from "react-number-format";
import { FlexVertical } from "../components/FlexComponents";
import { DesktopOnly, MobileOnly } from "../components/screensize";

const CheckinOutWrapper = styled.div`
  display: flex;
  font-family: "Roboto";
  color: #888;
  font-size: 85%;
`;

const CheckinOutWrapperMobile = styled(CheckinOutWrapper)`
  flex-direction: column;
`;

const ItemWrapper = styled.div`
  padding-right: 5px;
  padding-left: 5px;
  border-right: 1px solid #aaa;
  &:first-child {
    padding-left: 0px;
  }
  &:last-child {
    padding-right: 0px;
    border-right: 0px solid #aaa;
  }
`;

const ItemWrapperMobile = styled.div``;

const ReservationTitle = styled.div`
  font-size: 110%;
  padding-bottom: 2px;
  border-bottom: 1px solid #aaa;
  margin-bottom: 2px;
`;

const FieldValue = styled.span`
  color: #888;
`;

const Section = styled.div`
  display: flex:
  flex-direction: column;
  margin: 15px 0px;
`;

const DescriptionTitle = styled.div`
  font-size: 95%;
  border-bottom: 1px solid #aaa;
  padding-bottom: 2px;
`;

const DescriptionValue = styled.div`
  display: flex;
  background-color: #eee;
  font-size: 85%;
  padding: 5px;
`;

const FieldSpan = styled.span`
  font-size: 95%;
`;

const FieldSet = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 2px;
  padding-bottom: 2px;
  ${props => !props.noborder && "border-bottom: 1px solid #aaa;"}

  ${props => props.width && "width: " + props.width + ";"}
`;

const RefundableCostFields = props => {
  return (
    <>
      <FieldSet width="270px">
        <div>
          <FieldSpan>Total Due at Check In: </FieldSpan>
        </div>
        <div>
          <FieldSpan>
            <FieldValue>
              <NumberFormat
                value={props.cost}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale={true}
                suffix={" USD"}
              />
            </FieldValue>
          </FieldSpan>
        </div>
      </FieldSet>
      <FieldSet width="270px">
        <FieldSpan>Refundable Deposit: </FieldSpan>
        <FieldSpan>
          <FieldValue>
            <NumberFormat
              value={props.deposit}
              displayType={"text"}
              thousandSeparator={true}
              prefix={"$"}
              decimalScale={2}
              fixedDecimalScale={true}
              suffix={" USD"}
            />
          </FieldValue>
        </FieldSpan>
      </FieldSet>
    </>
  );
};

const NonRefundableCostFields = props => {
  return (
    <>
      <FieldSet width="270px">
        <div>
          <FieldSpan>Total Due: </FieldSpan>
        </div>
        <div>
          <FieldSpan>
            <FieldValue>
              <NumberFormat
                value={props.cost}
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
                decimalScale={2}
                fixedDecimalScale={true}
                suffix={" USD"}
              />
            </FieldValue>
          </FieldSpan>
        </div>
      </FieldSet>
    </>
  );
};

const ReservationDetails = props => {
  return (
    <>
      <ReservationTitle>
        Reservation: <FieldValue>{props.reservation}</FieldValue>
      </ReservationTitle>
      <DesktopOnly>
        <CheckinOutWrapper>
          <ItemWrapper>
            Check in on{" "}
            <Moment format="LL" tz={"Asia/Ho_Chi_Minh"}>
              {props.checkIn}
            </Moment>
          </ItemWrapper>
          <ItemWrapper>
            Check out on{" "}
            <Moment format="LL" tz={"Asia/Ho_Chi_Minh"}>
              {props.checkOut}
            </Moment>
          </ItemWrapper>
          <ItemWrapper>
            <Moment from={props.checkIn} ago>
              {props.checkOut}
            </Moment>
          </ItemWrapper>
          <ItemWrapper>{props.guests} guests</ItemWrapper>
        </CheckinOutWrapper>
      </DesktopOnly>
      <MobileOnly>
        <CheckinOutWrapperMobile>
          <ItemWrapperMobile>
            <FieldSet width="100%">
              Check in on
              <Moment format="LL" tz={"Asia/Ho_Chi_Minh"}>
                {props.checkIn}
              </Moment>
            </FieldSet>
          </ItemWrapperMobile>
          <ItemWrapperMobile>
            <FieldSet width="100%">
              Check out on{" "}
              <Moment format="LL" tz={"Asia/Ho_Chi_Minh"}>
                {props.checkOut}
              </Moment>
            </FieldSet>
          </ItemWrapperMobile>
          <ItemWrapperMobile>
            <FieldSet width="100%">
              Stay Duration:
              <Moment from={props.checkIn} ago>
                {props.checkOut}
              </Moment>
            </FieldSet>
          </ItemWrapperMobile>
          <ItemWrapperMobile>
            <FieldSet width="100%">
              <div />
              {props.guests} guests
            </FieldSet>
          </ItemWrapperMobile>
        </CheckinOutWrapperMobile>
      </MobileOnly>
      <Section>
        <DescriptionTitle>Room Description</DescriptionTitle>
        <DescriptionValue>
          <FlexVertical>
            {props.roomDescription.split("\n").map((item, i) => {
              return <span key={i}>{item}</span>;
            })}
          </FlexVertical>
        </DescriptionValue>
      </Section>
      <Section>
        {props.refundable ? (
          <RefundableCostFields {...props} />
        ) : (
          <NonRefundableCostFields {...props} />
        )}
      </Section>
    </>
  );
};

export default ReservationDetails;
