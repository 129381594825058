const { filteredFetch } = require("../errors");

const getSettings = () => {
  return filteredFetch(`/api/admin/settings`, {
    method: "GET",
    credentials: "include"
  })
    .then(res => res.json())
    .catch(err => {
      if (!err || (err && err.name !== "UnauthorizedError")) {
        throw new Error(err);
      }
    });
};

const updateSetting = (identifier, value) => {
  return filteredFetch(`/api/admin/settings`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify([{ identifier, value }])
  }).then(res => res.json());
};

const emailTest = toSend => {
  return filteredFetch(`/api/admin/settings/emailtest`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json"
      // "Content-Type": "application/x-www-form-urlencoded",
    },
    body: JSON.stringify({ to: toSend })
  }).then(res => res.json());
};

module.exports = {
  getSettings,
  updateSetting,
  emailTest
};
