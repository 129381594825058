import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  background-color: #a08036;
  border: none;
  color: white;
  padding: 15px 32px;
  ${props => props.height && `height: ${props.height};`}
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;

  &:hover {
    background-color: #805006;
  }

  &:active {
    background-color: #a08036;
  }
`;

const Button = props => {
  return <StyledButton {...props} />;
};

export default Button;
