import React from "react";
import styled from "styled-components";
import { FlexHorizontal, FlexVertical } from "../components/FlexComponents";

const StyledBox = styled.div`
  width: 50px;
  height: 50px;
  background-color: ${props => props.color};
  margin-left: 5px;
  margin-right: 5px;
  border-radius: 4px;
`;

const StyledMessage = styled.div`
  margin: 10px;
`;
const StatusWrapper = styled.div`
  margin-top: 100px;
  background-color: #eee;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  font-size: 85%;
`;

const ConfirmationStatus = props => {
  const green = "#0b0";
  const grey = "#ccc";
  const red = "#e44";
  const yellow = "#ff0";

  const getStatusBoxes = state => {
    const cubes = [];
    let message = "";
    switch (state) {
      case "PENDING":
        cubes.push(green);
        cubes.push(grey);
        cubes.push(grey);
        message = "Waiting for hotel to verify, please check back in 24 hours.";
        break;
      case "CHARGED":
        cubes.push(green);
        cubes.push(green);
        cubes.push(grey);
        message =
          "The card on file has been successfully charged, we look forward to your visit.";
        break;
      case "DEPOSITVERIFY":
        cubes.push(green);
        cubes.push(green);
        cubes.push(grey);
        message =
          "The card on file has been successfully charged the indicated deposit, we look forward to your visit in the future.";
        break;
      case "HOTELCANCEL":
        cubes.push(green);
        cubes.push(red);
        cubes.push(grey);
        message =
          "This reservation has been canceled, if you believe there is an error please email sales@lalunahoian.com for more information.";
        break;
      case "COMPLETE":
        cubes.push(green);
        cubes.push(green);
        cubes.push(green);
        message = "This is complete, we hope you enjoyed your stay.";
        break;
      case "PENDING_REFUND":
        cubes.push(green);
        cubes.push(yellow);
        cubes.push(grey);
        message =
          "You refund is being processed, hope you have a wonderful day.";
        break;
      case "REFUNDED":
        cubes.push(green);
        cubes.push(green);
        cubes.push(green);
        message = "You refund is complete.";
        break;
      default:
        cubes.push(grey);
        cubes.push(grey);
        cubes.push(grey);
        message =
          "Unknown status, please contact sales@lalunahoian.com for more details.";
    }

    return { cubes, message };
  };

  const statusDetails = getStatusBoxes(props.status);
  return (
    <FlexHorizontal center>
      <StatusWrapper>
        <FlexVertical crosscenter>
          <FlexHorizontal center>
            {statusDetails.cubes.map(item => (
              <StyledBox color={item} />
            ))}
          </FlexHorizontal>
          <StyledMessage>{statusDetails.message}</StyledMessage>
        </FlexVertical>
      </StatusWrapper>
    </FlexHorizontal>
  );
};

export default ConfirmationStatus;
