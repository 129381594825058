// eslint-disable-next-line
import React, { useState, useEffect } from "react";
import ReservationDetails from "../user/ReservationDetails";
import {
  getConfirmation,
  updateState,
  getAudits
} from "../api/admin/confirmations";
import { LabeledRadioOption } from "../components/LabeledControls";
import { FlexHorizontal } from "../components/FlexComponents";
import { ContinueModal } from "../components/modal";
import Button from "../components/button";
import styled from "styled-components";
import { getMessageForState } from "../lib/states";
import CreditCardDisplay from "./CreditCardDisplay";
import AuditItem from "./AuditItem";

const StateSpan = styled.span`
  font-size: 110%;
  font-weight: bold;
`;

/*const CreditCardDisplay = props => {
  const Border = styled.div`
    border: 1px solid black;
    border-radius: 10px;
    width: 320px;
    height: 190px;
    overflow: hidden;
    background-color: #eee;
    font-family: "Share Tech Mono", monospace;
    font-weight: bold;
  `;
  const Stripe = styled.div`
    background-color: black;
    margin-top: 10px;
    height: 30px;
    width: 320px;
  `;

  const CardNumber = styled.div`
    margin-top: 40px;
    font-size: 20px;
    width: 320px;
  `;
  const ExpLabel = styled.div`
    font-size: 10px;
    width: 35px;
  `;
  const Expiration = styled.div`
    margin-left: 30px;
    margin-top: 10px;
  `;
  const Spacer = styled.div`
    width: 120px;
  `;
  const CardHolder = styled.div`
    margin-top: 25px;
  `;
  const formatCardNumer = number => {
    let numberParts = [];
    for (let x = 0; x < number.length; x++) {
      numberParts.push(<>{number[x]}</>);
      if ((x + 1) % 4 === 0) {
        numberParts.push(<>&nbsp;&nbsp;</>);
      }
    }
    return numberParts;
  };
  return (
    <Border>
      <Stripe />
      <CardNumber>
        <FlexHorizontal center>
          {formatCardNumer(props.cardNumber)}
        </FlexHorizontal>
      </CardNumber>

      <Expiration>
        <FlexHorizontal crosscenter>
          <ExpLabel>Valid Thru</ExpLabel>
          <span>{props.cardExpire}</span>
          <Spacer />
          <ExpLabel>CCV Code</ExpLabel>
          <span>{props.cardSecurityCode}</span>
        </FlexHorizontal>
      </Expiration>
      <FlexHorizontal center>
        <CardHolder>{props.cardName}</CardHolder>
      </FlexHorizontal>
    </Border>
  );
};*/

const SectionWrapper = styled.div`
  padding: 20px;
  border-bottom: 2px solid #888;
`;

const AuditContainer = styled.div`
  min-height: 200px;
  max-height: 200px;
  height: 200px;
  overflow-y: scroll;
  background-color: #eee;
  font-size: 80%;
`;

const ConfirmationStatus = props => {
  const [reservation, setReservation] = useState(null);
  const [currentState, setCurrentState] = useState(null);
  const [creditCard, setCreditCard] = useState(null);
  const [possibleStates, setPossibleStates] = useState(null);
  const [newState, setNewState] = useState(null);
  const [confirmChange, setConfirmChange] = useState(null);
  const [audits, setAudits] = useState(null);

  const loadConfirmation = () => {
    getConfirmation(props.match.params.code)
      .then(res => {
        //console.log(res);
        setReservation(res.reservation);
        setCurrentState(res.state);
        setCreditCard(res.creditCard);
        setPossibleStates(res.possibleStates);
      })
      .catch(error => {
        console.log(error);
        if (error.name !== "UnauthorizedError") {
          props.history.push("/admin");
        }
      });
  };

  const loadAudits = () => {
    getAudits(props.match.params.code)
      .then(res => {
        setAudits(res);
      })
      .catch(error => {
        console.log(error);
        if (!["ForbiddenError", "UnauthorizedError"].includes(error.name)) {
          props.history.push("/admin");
        }
      });
  };

  useEffect(() => {
    if (!reservation) {
      loadConfirmation();
    }
    if (!audits) {
      loadAudits();
    }
  }, [props.match.params.code]);

  const getValidStateOptions = () => {
    const toRet = [];
    if (possibleStates) {
      for (let possibleState of possibleStates) {
        toRet.push({
          displayValue: possibleState.name,
          value: possibleState.stateName
        });
      }
    }
    return toRet;
  };

  return (
    <>
      <SectionWrapper>
        {reservation && (
          <ReservationDetails
            reservation={reservation.reservationNumber}
            guests={reservation.numberGuests}
            checkIn={reservation.checkIn}
            checkOut={reservation.checkOut}
            roomDescription={reservation.description}
            cost={reservation.cost}
            deposit={reservation.deposit}
            refundable={reservation.refundable}
          />
        )}
      </SectionWrapper>
      <SectionWrapper>
        <FlexHorizontal center>
          <span>
            Customer Confirmation Link:{" "}
            <a
              href={`${window.location.origin}/confirmation/${
                props.match.params.code
              }`}
            >{`${window.location.origin}/confirmation/${
              props.match.params.code
            }`}</a>
          </span>
        </FlexHorizontal>
      </SectionWrapper>
      <SectionWrapper>
        <FlexHorizontal center>
          {creditCard && <CreditCardDisplay {...creditCard} />}
          {creditCard === null && (
            <span>There are no payment details to display</span>
          )}
          {creditCard === false && (
            <span>You are not authorized to view credit card details</span>
          )}
        </FlexHorizontal>
      </SectionWrapper>
      <SectionWrapper>
        <FlexHorizontal center>
          <StateSpan>
            Current State: {getMessageForState(currentState)}
          </StateSpan>
        </FlexHorizontal>
        {getValidStateOptions().length > 0 && (
          <LabeledRadioOption
            label={"Change State To"}
            labelWidth={"120px"}
            name={"newState"}
            options={getValidStateOptions()}
            onValueChange={setNewState}
            value={newState}
            dontIncludeFlex
          />
        )}
      </SectionWrapper>
      {audits && (
        <SectionWrapper>
          <AuditContainer>
            {audits.map((audit, index) => (
              <AuditItem key={index} audit={audit} />
            ))}
          </AuditContainer>
        </SectionWrapper>
      )}
      <SectionWrapper>
        <FlexHorizontal space>
          {newState && (
            <Button onClick={() => setConfirmChange(true)}>Update</Button>
          )}
          <Button onClick={() => props.history.push("/admin")}>Back</Button>
        </FlexHorizontal>
      </SectionWrapper>
      {confirmChange && (
        <ContinueModal
          onCancel={() => setConfirmChange(null)}
          onContinue={() => {
            updateState(props.match.params.code, newState).then(res => {
              loadConfirmation();
              loadAudits();
              setConfirmChange(null);
              setNewState(null);
            });
          }}
          message={"Are you sure you would like to update the status?"}
        />
      )}
    </>
  );
};

export default ConfirmationStatus;
