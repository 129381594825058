import React, { useState } from "react";
import styled from "styled-components";
import { FlexHorizontal, FlexVertical } from "../components/FlexComponents";
import Button from "../components/button";
import TextBox from "../components/textbox";

const Wrapper = styled.div`
  margin-top: 20px;
`;

const LabelWrapper = styled.div`
  background-color: #eee;
  padding: 10px;
  margin: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
`;

const Title = styled.div`
  font-size: 200%;
  font-weight: bold;
  margin-bottom: 50px;

  @media (max-width: 414px) {
    font-size: 150%;
  }
`;

const Home = props => {
  const [confirmationCode, setConfirmationCode] = useState("");

  const loadReference = () => {
    props.history.push(`/confirmation/${confirmationCode.toLowerCase()}`);
  };

  return (
    <Wrapper>
      <FlexVertical crosscenter>
        <Title>Credit Card Processing</Title>
        <LabelWrapper>
          {"Please enter the provided booking confirmation code to proceed."}
        </LabelWrapper>
        <FlexHorizontal center>
          <TextBox
            onChange={event => setConfirmationCode(event.target.value)}
            placeholder={"confirmation code"}
            onKeyPress={event => event.key === "Enter" && loadReference()}
          />
          <Button onClick={loadReference}>Go</Button>
        </FlexHorizontal>
      </FlexVertical>
    </Wrapper>
  );
};

export default Home;
