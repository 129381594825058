//import React from "react";

import styled from "styled-components";

export const NotMobile = styled.div`
  // 480 -- maybe not enough so switch to 768
  display: ${props => (props.flex ? "flex" : "unset")};
  @media (max-width: 768px) {
    display: none;
  }
`;

export const NotDesktop = styled.div`
  display: none;

  @media (max-width: 768px) {
    display: ${props => (props.flex ? "flex" : "unset")};
  }
`;

export const DesktopOnly = NotMobile;
export const MobileOnly = NotDesktop;
